@import '../../styles/spacing.less';
@import '../../styles/colours.less';

.vec-radio-group-horizontal {
    width: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.vec-radio-group-horizontal .vec-radio-button-horizontal {
    margin-left: 4px;
    margin-right: 4px;
    flex-grow: 1;
    border-radius: 8px !important;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1.5px solid #e9ecf0 !important ;
}

.vec-radio-group-horizontal .vec-radio-button-horizontal .ant-radio-button {
    border-radius: 8px !important;
}

.vec-radio-group-horizontal
    .vec-radio-button-horizontal.ant-radio-button-wrapper-checked {
    border: none !important;
    box-shadow: none !important;
}

.vec-radio-group-horizontal
    .ant-radio-button-wrapper-checked.vec-radio-button-horizontal
    .ant-radio-button {
    background: @primary-button-light;
}

.vec-radio-group-horizontal .vec-radio-button-horizontal::before {
    visibility: hidden;
}

.vec-radio-group-horizontal .vec-radio-button-horizontal span {
    font-weight: bold;
}

.vec-radio-group-horizontal
    .ant-radio-button-wrapper.vec-radio-button-horizontal {
    color: black;
}

.vec-radio-group-horizontal
    .vec-radio-button-horizontal.ant-radio-button-wrapper-checked
    span {
    color: white;
}

label .ant-radio-inner::after {
    transform: none!important;
}