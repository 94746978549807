@import '../../styles/spacing.less';

@media screen and (max-width: @mobile) {
    .grow-mobile {
        flex-grow: 1;
    }
}

.ant-form .primary-form-cta {
    margin-top: @xl;
    margin-bottom: @3xl;
    height: 56px;
}

.error-interstitial .interstitial-paragraph span {
    font-weight: bold;
}

.error-interstitial span.text-link{
    font-weight: normal;
    text-decoration: underline;
}

.header-bottom.header::after {
    height: unset;
}
