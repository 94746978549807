@import '../../styles/app.less';

.header {
    height: 80px;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr auto;
    z-index: 1;
    background-color: white;
}

.header::after {
    content: '';
    border-bottom: 1px solid #9eacbc;
    width: 100%;
    opacity: 0.3;
    grid-column: span 2;
    height: 0;
}

.header > * {
    display: flex;
    align-items: center;
    margin: 24px;
}

.header .primary-cta.ant-btn-primary {
    width: auto;
    padding: 8px 12px;
    line-height: 150%;
    height: auto;
    background: @primary-color;
}

.header a {
    margin: 0;
    width: fit-content;
}

#logo {
    max-height: 100%;
    max-width: 100%;
    margin: 0 0 0 24px;
}

.button-grid {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 8px;
    margin: auto 16px;
}

#logout-icon > path,
#logout-icon {
    height: 18px;
    width: 18px;
}
#logout-button {
    padding: 10px;
    height: auto;
    border: 1.5px solid #034d83;
}

.logout-modal .ant-modal-title {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 32px;
    letter-spacing: -0.5px;
    font-feature-settings: 'liga' off;

    color: #13171c;
}

.logout-modal .ant-modal-header,
.logout-modal .ant-modal-footer {
    border: none;
}

.logout-modal .ant-modal-footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 24px 24px;
}

.logout-modal .ant-modal-body {
    color: #535762;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
}

.logout-modal .ant-modal-footer button {
    color: #13171c;
    border: 2px solid #e9ecf0;
    box-shadow: none;
}

.logout-modal .ant-modal-close-icon path {
    height: 12px;
    width: 12px;
    color: #13171c;
    stroke-width: 1.5px;
}
