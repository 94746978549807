.ant-form-item.upToDate {
    padding: 8px 16px 0px;

    .ant-form-item-label {
        color: #535762;
        font-weight: 400;
        margin-bottom: 16px;
    }

    .ant-form-item-control-input-content {
        background: #fff;
        position: relative;
        z-index: 1;
        border-radius: 16px;
        margin-bottom: 16px;
        padding: 16px;
        border: 1.5px solid #e9ecf0;

        .ant-checkbox-wrapper {
            color: #13171C;
            font-weight: 700;
        }

        .ant-checkbox-inner {
            width: 24px;
            height: 24px;
            border-radius: 4px;
        }

        .ant-checkbox-inner::after {
            left: 30%;
        }
    }
}