@import '../../styles/app.less';

.consult-card {
    border-radius: 16px;
}

// Content
.consult-card > *:last-child {
    border-left: 1.5px solid #e9ecf0;
    border-right: 1.5px solid #e9ecf0;
    border-bottom: 1.5px solid #e9ecf0;
    border-radius: 0 0 16px 16px;
    padding: 24px;
}
// Content
.consult-card > *:last-child p {
    margin-bottom: 0;
}

.consult-card > *:last-child > div:not(:first-child) {
    margin-top: 16px;
}

// Header
.consult-card > *:first-child {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background: #e9ecf0;
    padding: 16px 24px;
    margin-bottom: 0;

    font-size: 18px;

    font-weight: 700;
}

.consult-card .warning {
    background: #fff6e8;
    border-radius: 8px;
    color: #af5304;
    padding: 16px;
}

.consult-card strong {
    color: @heading-color;
}
