@import '../../styles/colours.less';
@import '../../styles/spacing.less';

.heading {
    font-size: 18px;
    font-weight: bold;
    background-color: @grey-background;
    padding: @md;
    border-radius: @radius-lg;
    color: black;
    margin-bottom: 24px;
}

.show-more-button {
    border: 2px solid #e9ecf0;
    margin: @sm 0;
}

.anticon-loading {
    font-size: 24px;
    color: #034d83;
    margin-top: @md;
}

.search-criteria {
    margin-bottom: @xl;
}

.search-criteria--text {
    font-weight: bold;
    margin-bottom: @xs !important;
}

.search-criteria--button {
    padding: 0 !important;
    height: auto !important;
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
    border: none !important;
    box-shadow: none !important;
}

.search-criteria--button .ant-typography {
    margin-bottom: 0;
    font-weight: normal;
    color: #006fc3;
    text-decoration: underline;
}

.search-criteria--zip {
    margin-top: @lg;
}

.submit-pharmacy-search {
    margin-top: @lg;
    align-items: center;
}

.pharmacy-closed-warning {
    color: #c3362e;
    font-size: 16px;
}

.open-checkbox .ant-checkbox-inner {
    border-radius: 4px;
    height: 24px;
    width: 24px;
}

.error-wrapper {
    display: flex;
    margin-top: 32px;
    border-radius: 8px;
}

.error-left-section {
    background: #af5304;
    width: 4px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.error-right-section {
    background: #fff6e8;
    padding: 16px 16px 16px 20px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 100%;
}
