.choosePatient {
    margin: 14px 0px;
}

.required {
    color: #c3362e;
}

.buttonLink {
    color: #006FC3;
    height: auto;
    padding: 0px;
    line-height: normal;
    text-align: left;
    font-weight: normal;

    span {
        text-decoration: underline;
    }
}

.modal .ant-modal-body {
    padding-bottom: 16px;
}
