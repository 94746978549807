@import '~antd/dist/antd.less';
@import './spacing.less';
@import './colours.less';

@font-family: 'Lato', sans-serif;
@font-size-base: 16px;
@heading-color: #13171c;
@text-color: #535762;
@primary-color: #034d83;
@success-color: #0e7016;
@error-color: #c3362e;
@border-radius-base: @xs;
@border-color-base: #4e5a70;
@screen-xl: @desktop;

.ant-layout {
    background: @primary-background;
}

main.ant-layout-content {
    height: 100%;
}

#root,
.ant-layout {
    height: 100%;
}

.ant-btn {
    padding: @sm @lg;
    height: @2xl;
    font-weight: bold;
    line-height: 1;
}

.ant-btn-primary {
    border-color: #1890ff;
}

.ant-btn-background-ghost {
    border: 2px solid #fff;
    margin: 0;
}

h1.ant-typography,
.ant-typography h1 {
    font-size: 30px;
}

.ant-typography strong {
    color: @heading-color;
}

.text-center {
    text-align: center;
}

.text-white {
    color: #fff !important;
}

.text-blue {
    color: #006FC3;
}

.text-black {
    color: black !important;
}

.text-red {
    color: #c3362e;
}

.no-gutter-bottom {
    margin-bottom: 0 !important;
}

.gutter-right {
    margin-right: @sm!important;
}

.flex {
    display: flex;
}

.column {
    flex-direction: row;
}

.grow {
    flex-grow: 1;
}

.full-width {
    width: 100%;
}

.no-margin {
    margin: 0 !important;
}

.title {
    font-size: 36px !important;
    font-weight: bold !important;
    margin-bottom: @lg !important;
}

.content {
    height: calc(100vh - 80px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.ant-alert {
    display: flex;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: max-content;
    margin: 48px 25px 0 25px;
    box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12),
        0px 8px 16px -6px rgba(24, 39, 75, 0.08);

    .ant-alert-message {
        font-size: 14px;
    }

    .ant-alert-close-icon {
        align-self: flex-start;
        margin-top: 5px;
        color: #c3362e;
    }
}

.ant-alert > svg {
    align-self: flex-start;
    width: 20px;
    height: 20px;
}

.ant-alert-error {
    border: 1.5px solid rgba(195, 54, 46, 0.4);

    .ant-alert-message {
        color: #c3362e;
    }
}

.ant-alert-success {
    background-color: #e6ffe9;
    border: 1.5px solid rgba(14, 112, 22, 0.4);

    .ant-alert-message {
        color: #0e7016;
    }
}

.text-link span {
    color: #246fc3;
    text-decoration: underline;
}

.text-link {
    border-radius: 0;
}

.hide-x-overflow {
    overflow-x: hidden;
}
