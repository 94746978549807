.progress-bar {
    background: linear-gradient(270deg, #44bdd0 0%, rgba(249, 243, 167, 0) 100%),
        #034d83;
    border-radius: 0px 8px 8px 0px;
    height: 8px;
}
.progress-bar--0 {
    width: 0;
}

.progress-bar--1 {
    width: 20%;
}

.progress-bar--2 {
    width: 40%;
}

.progress-bar--3 {
    width: 60%;
}

.progress-bar--4 {
    width: 80%;
}
