@import '../../styles/app.less';

.consultation-card {
    border: 1.5px solid #e9ecf0;
    border-radius: 16px;
    padding: 16px 0px;
    display: grid;
    gap: 12px;
    margin-bottom: 16px;

    > * {
        padding-left: 16px !important;
        padding-right: 16px !important;
    }
}

.consultation-card .subheader {
    padding: 9px 16px;
    background-color: #F9FAFB;
    border: solid #E9ECF0;
    border-width: 1px 0px;
}

.consultation-card .link {
    color: #006FC3;
    text-align: left;
    padding: 0px 16px;
    font-weight: normal;
}

.consultation-card p {
    margin: 0;
    color: #535762;
    line-height: 150%;
}
.consultation-card strong {
    margin: 0;
    color: #535762;
    line-height: 150%;
}

.consultation-card .chip {
    padding: 8px;
    border-radius: 100px;
    text-transform: uppercase;
    font-size: 11px;
    width: auto;
}

.consultation-card .chip.future {
    color: #006fc3;
    background: #e3f4ff;
}
.consultation-card .chip.past {
    color: #0e7016;
    background: #e6ffe9;
}

.consultation-card .primary-form-cta {
    background: #007ad1;
}

.consultation-card .primary-form-cta > div {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.consultation-card .cta-icon {
    stroke-width: 25;
    stroke: white;
}

.consultation-card .href-button-container a {
    margin: auto;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.consultation-card a > div {
    flex-grow: 1;
}

.consultation-card hr {
    width: 100%;
    border: 1px solid #e9ecf0;
}

.consultation-title {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 32px;
    letter-spacing: -0.5px;
    color: #13171c;
    margin-bottom: 12px;
}

.contact-number {
    text-decoration: underline;
    color: rgb(0, 110, 191);
}

.primary-cta {
    margin-bottom: 16px;
}
