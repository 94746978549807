@import '../../styles/spacing.less';
@import '../../styles/colours.less';

.home .ant-typography {
    font-size: 18px;
}

.home h1.ant-typography {
    font-size: 36px;
}

.home h2.ant-typography {
    font-size: 30px;
}

.home header.ant-layout-header {
    color: @primary-background;
    background: @home-header-background;
}

#container-1 {
    padding: @2xl @lg @4xl @lg;
}

#container-1__headline {
    margin-bottom: @lg;
}

#container-1__cta {
    margin-top: 14px;
    margin-bottom: 0;
}

#container-2 {
    margin: @3xl @lg;
}

#container-2__text-box {
    text-align: center;
}

#container-2__cta {
    margin-top: @lg;
    margin-bottom: 0;
}

#container-2__subheadline {
    margin-bottom: @2xl;
}

#container-3 {
    margin: @6xl @lg;
}

#container-3__headline {
    margin-bottom: @lg;
}

#container-3__subheadline {
    margin-bottom: @xl;
}

@media (min-width: 601px) {
    .home .background-image {
        background-color: black;
        background-image: linear-gradient(
                rgba(0, 0, 0, 0.5),
                rgba(0, 0, 0, 0.5)
            ),
            url('https://bit.ly/3mGM7II');
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }
    #container-3__subheadline {
        margin-bottom: @xl;
        width: 35em;
    }
}

@media (max-width: 600px) {
    .home .background-image {
        height: 519px;
        width: auto;
        border-radius: 0px;
        padding: 0px, 24px, 0px, 24px;
        background-color: black;
        background-image: linear-gradient(
                rgba(0, 0, 0, 0.5),
                rgba(0, 0, 0, 0.5)
            ),
            url('https://bit.ly/3mGM7II');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
}

.home .ant-btn-primary {
    border-color: #1890ff;
    background: @primary-button-light;
}

.home h3.ant-typography {
    font-size: 23px;
}

.PriceCardCol {
    display: flex;
}

#container-1__div__img {
    overflow: hidden;
}

#container-1__img > img,
#container-1__img > source {
    max-height: 100%;
    max-width: 100%;
}
.container_row_faqs {
    background-color: @grey-ligth-background;
    padding: 6% 0 6% 0;
    font-family: Lato !important;

    .ant-collapse-header {
        font-weight: 500;
    }
}

.ant-collapse-item-active {
    .ant-collapse-header {
        color: @accordion-arrow-active !important;
    }
}
.ant-collapse-item {
    border-bottom: 1px solid @divider-border-accordion !important;
}
.ant-collapse-item:last-child {
    border-bottom: 0px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    font-size: 17px;
}

/*
@media screen and (min-width: @mobile) and (max-width: @tablet) {
    .container_col_faqs {
        padding: 0 5% 0 5% !important;
    }
}
*/
@media screen and (min-width: @desktop) {
    #container-1 {
        padding: 0 @2xl 0 auto;
        margin: @7xl auto;
    }

    #container-1_img {
        left: 50%;
        position: relative;
        transform: translateX(-50%);
    }

    #container-1__img > img,
    #container-1__img > source {
        max-height: 790px;
        max-width: unset;
        left: 50%;
        position: relative;
        transform: translateX(-50%);
    }

    #container-2 {
        margin: @6xl auto;
        max-width: 1128px;
    }

    #container-2__text-box {
        margin-right: @xl;
        text-align: left;
        max-width: 333px;
    }

    #container-2__headline {
        font-size: 36px;
    }

    .container_col_faqs {
        padding: 0 15% 0 15%;
        margin-top: 3%;
    }

    .ant-collapse-header {
        font-size: 18px;
    }

    .chevron-icon-active {
        transform: rotate(180deg) translateY(50%) !important;
    }
}
