@import '../../styles/app.less';
@import '../../styles/spacing.less';
@import '../../styles/colours.less';

.checkout-complete {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkout-complete--container {
    width: 232px;
    height: 236px;
    margin-bottom: 112px;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.checkout-complete--container svg {
    font-size: 50px;
}

.checkout-complete--title {
    font-size: 30px;
    margin-top: @md;
}

.checkout-complete--bold-desc {
    font-weight: bold;
}
