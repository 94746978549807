@import url('../../styles/spacing.less');
.modal {
    width: 500px !important;
    padding: 24px !important;
    cursor: default;
}
.ant-modal-header,
.ant-modal-footer {
    border-bottom: none;
    border-top: none;
}
.ant-modal-body {
    padding: 0px 24px 0px 24px;
}
.ant-modal-title {
    font-weight: 700;
    font-size: 36px;
    padding-right: 15%;
    line-height: 48px;
}
.staySignedButton {
    color: #656565;
    font-weight: bold;
    font-family: Lato !important;
}
