@import '../../styles/app.less';

.blue {
    color: #007ad1;
}

.video-privacy .ant-typography {
    color: #034d83 !important;
}
.video-privacy {
    background-color: #dbf0ff;
    color: #034d83 !important;
    font-weight: bold;
    margin-top: @xl;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    border-radius: 8px;
}

.lock-icon > svg {
    height: 26.67px;
    margin-right: 5px;
}

.upload-video-btn {
    height: @2xl;
    min-width: 176px;
    text-align: center;
}

.upload-video-btn--pending,
.upload-video-btn--error {
    background-color: #007ad1 !important;
    color: white !important;
}

.upload-video-btn--success {
    background-color: white !important;
    color: #13171c !important;
    border: 1px solid #e9ecf0 !important;
    box-shadow: none !important;
}

#video-upload .ant-form-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .ant-btn {
        padding: 0px !important;
    }
}

.ant-alert-icon {
    margin: auto;
}

.video-container {
    height: 275px;
    width: 175px !important;
    margin-bottom: @sm !important;
    margin-top: @sm;
    background-color: #e9ecf0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    .react-thumbnail-generator,
    .react-thumbnail-generator > img {
        max-width: 100%;
        max-height: 100%;
    }
}

.video-container-success {
    background-color: rgb(11, 7, 3) !important;
}

.video-container--video {
    max-height: 100%;
    max-width: 100%;
}

.icon-circle {
    background-color: white;
    height: 116px;
    width: 116px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-icon > svg {
    width: 70px;
    height: 50px;
    color: #034d83;
}

.video-icon.video-icon-success > svg {
    color: green;
}

#video-upload .loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .ant-typography {
        color: @heading-color;
    }

    .loading-container--slow {
        font-size: 14px;
    }
}

#video-upload .ant-form-item-control-input-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.video-subtitle {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 15px;
    color: #13171c !important;
}
