@import '../../styles/spacing.less';

.payment-grid {
    display: grid;
    grid-template-columns: 1fr auto;
}

.payment-grid > hr {
    grid-column: span 2;
    width: 100%;
    border-color: #e9ecf0;
    opacity: 0.3;
}

.payment-grid > div {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 8px;
}

.order-summary-cards {
    display: grid;
    grid-gap: 16px;
    margin-bottom: @xl;
    margin-top: @xl;
}

.order-summary-chip {
    color: #006fc3;
    padding: 4px 8px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: @xl;
    background-color: #e3f4ff;
    border-radius: 100px;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    font-style: normal;
    letter-spacing: 1px;
}

.order-summary-text {
    text-align: center;
}

.order-summary-cta-container {
    background-color: rgb(252, 240, 241);
    border-radius: @md;
    padding: @lg;
    white-space: break-spaces;
}

.order-summary-cta-container > span {
    color: rgb(181, 66, 44);
}

.order-summary-cta-container .primary-form-cta {
    margin: @md 0 0 0;
}

.card-logo > svg {
    height: 20px;
    width: 32px;
}

.card-logo {
    display: flex;
    align-items: center;
}

.summary-card {
    border-radius: 16px;
}

.summary-card strong {
    color: #13171C;
}

.summary-card .label {
    color: #13171C;
}

.summary-card .grid {
    display: grid;
    grid-template-columns: auto 1fr;
    white-space: break-spaces;
}

// Content
.summary-card > *:last-child {
    border-left: 1.5px solid #e9ecf0;
    border-right: 1.5px solid #e9ecf0;
    border-bottom: 1.5px solid #e9ecf0;
    border-radius: 0 0 16px 16px;
    padding: 24px;
}
// Content
.summary-card > *:last-child p {
    margin-bottom: 0;
}

.summary-card > *:last-child > div:not(:first-child) {
    margin-top: 16px;
}

// Header
.summary-card > *:first-child {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background: #e9ecf0;
    padding: 16px 24px;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 700;
}

.summary-card .warning {
    background: #fff6e8;
    border-radius: 8px;
    color: #af5304;
    padding: 16px;
}