@import '../../styles/app.less';

.health-profile .form-help-text {
    margin-bottom: @xs;
}

.details-container {
    background-color: #e9ecf0;
    border-radius: 16px;
    margin-top: 24px;
}

.details-title {
    padding: 16px;
    font-weight: bold;
    color: #13171c !important;
    font-size: 18px;
}

.fields-container {
    background-color: white;
    padding: 0px 16px 16px;
    border: 1.5px solid #e9ecf0;
    border-radius: 0px 0px 16px 16px;
    
    > .ant-form-item {
        font-size: 16px;
        line-height: 24px;
        margin-top: 16px !important;
    }
}

.already-created > .ant-form-item-label {
    padding: 0px !important;
}

.consent-checkbox .ant-checkbox-inner {
    border-radius: 4px;
    height: 24px;
    width: 24px;
    box-sizing: border-box;
}

.consent-checkbox .ant-checkbox-inner::after {
    position: absolute;
    width: calc(11px * 9 / 16);
    height: 11px;
    top: 50% !important;
    left: 50% !important;
    transform-origin: 70% 50%;
    transform: translate(-50%, -50%) scale(1) rotate(45deg) !important;
}

.terms-container {
    margin-top: 32px;
    background-color: #dbf0ff;
    padding: 16px;
    border-radius: 16px;
}

.error .consent-checkbox {
    background-color: #fff1f1;
    color: #c3362e;
}

.error .ant-checkbox-inner {
    border-color: #c3362e;
}

.ticked .consent-checkbox {
    background-color: #e6ffe9;
    color: #0e7016;
}

.consent-checkbox {
    background-color: #f7fcff;
    width: 100%;
    padding: 16px !important;
    border-radius: 8px;
    display: table;
}

.consent-checkbox span {
    display: table-cell;
    vertical-align: middle;
    font-weight: bold;
}

ul {
    padding-left: 16px;
}

.terms-container .text-link {
    padding: 0;
    border: none;
    height: fit-content;
    width: fit-content;
}

.state-select {
    .ant-select-selector {
        height: 64px !important;
    }
}
