@import '../../styles/colours.less';
@import '../../styles/spacing.less';

.pharmacy-card {
    border: 1.5px solid @grey-background;
    border-radius: @radius-lg;
    padding: @md;
    margin: @md 0;
}

.pharmacy-card .ant-typography {
    margin-bottom: 0 !important;
}

.pharmacy-card .ant-radio-input {
    height: 24px;
    width: 24px;
}

.pharmacy-card-button {
    border: 1.5px solid #9eacbc !important;
    border-radius: @radius-lg !important;
    padding: @md !important;
    display: flex !important;
    align-items: center !important;
    width: 100% !important;
    height: 56px !important;
}

.pharmacy-card-button.active {
    border: 3px solid #0173ca !important;
    background-color: #f7fcff !important;
    padding: calc(@md - 1.5px) !important;
}

.pharmacy-card-button .ant-typography {
    font-weight: 700;
    color: black;
}

.pharmacy-card-button--label {
    flex-grow: 1;
    font-size: 16px;
    text-align: left;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pharmacy-card-button--distance {
    font-size: 11px;
    background-color: #4e5d70;
    border-radius: 100px;
    padding: 8px;
    height: 32px;
    display: flex;
    margin-left: @xs;
}

.pharmacy-card-button--distance.active {
    background-color: #034d83;
}

.pharmacy-card-button--distance .ant-typography {
    align-self: center;
}

.pharmacy-card-content {
    padding: @md @md 0 @md;
}

.pharmacy-card-content--hours-button {
    padding: 0 !important;
    height: auto !important;
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
    border: none !important;
    box-shadow: none !important;
}

.pharmacy-card-content--text {
    margin-bottom: 5px;
    font-size: 16px;
}

.pharmacy-card-content--hours-label {
    margin: 0 !important;
    text-align: left;
    font-weight: normal;
}

.pharmacy-card-content--hours-list {
    margin-top: 8px;
    font-size: 16px;
    border-top: solid 1.5px #e9ecf0;
}

/* Radio button styling */

label.pharmacy-select-radio-item .ant-radio-inner {
    width: 24px;
    height: 24px;
}

.pharmacy-select-radio-item input {
    margin: auto;
}

label.pharmacy-select-radio-item .ant-radio-inner::after {
    background-color: #0173ca;
    position: absolute;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

label.ant-radio-wrapper-checked.pharmacy-select-radio-item .ant-radio-inner {
    border: 2px solid #0173ca;
}

.pharmacy-hours-row {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: #e9ecf0 1.5px solid;
}
