@import '../../styles/spacing.less';

.content-wrapper {
    padding: @xl @xl 0;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: visible;
    height: calc(100% - 80px - 8px);
}

.content .content-wrapper {
    height: auto;
    overflow-y: unset;
}
