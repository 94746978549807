@import '../../styles/app.less';

label.clinical-protocol-radio-item,
label.clinical-protocol-checkbox-item {
    border: 1.5px solid #e9ecf0;
    border-radius: 8px;
    padding: 16px;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
}

label.clinical-protocol-radio-item .ant-radio-inner {
    width: 24px;
    height: 24px;
    border: 1px solid #4e5a70;
}

.clinical-protocol-radio-item > * {
    margin-top: auto;
    margin-bottom: auto;
}

.clinical-protocol-radio-item input,
.clinical-protocol-checkbox-item input {
    margin: auto;
}

.clinical-protocol-checkbox-item > * {
    top: 0 !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.clinical-protocol-checkbox-item > span {
    font-weight: bold;
    font-size: 16px;
    color: #13171c;
}

.clinical-protocol-checkbox-item::after {
    margin-top: auto !important;
    margin-bottom: auto !important;
    content: none !important;
}

.clinical-protocol-checkbox-item .ant-checkbox-inner {
    border: 1px solid #4e5a70;
    box-sizing: border-box;
    border-radius: 4px;
    height: 24px;
    width: 24px;
    position: relative;
}

.clinical-protocol-checkbox-item .ant-checkbox-checked .ant-checkbox-inner {
    background: #0173ca;
    border: 1px solid #0173ca !important;
}

.clinical-protocol-checkbox-item .ant-checkbox-inner::after {
    position: absolute;
    width: calc(11px * 9 / 16);
    height: 11px;
    top: 50% !important;
    left: 50% !important;
    transform-origin: 70% 50%;
    transform: translate(-50%, -50%) scale(1) rotate(45deg) !important;
}

.ant-checkbox-checked::after {
    border: none !important;
}

label.clinical-protocol-radio-item .ant-radio-inner::after {
    background-color: #0173ca;
    position: absolute;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

label.ant-radio-wrapper-checked.clinical-protocol-radio-item,
label.ant-checkbox-wrapper-checked.clinical-protocol-checkbox-item {
    padding: calc(16px - 1.5px);
    background: #f7fcff;
    border: 3px solid #0173ca;
}

label.ant-radio-wrapper-checked.clinical-protocol-radio-item .ant-radio-inner {
    border: 2px solid #0173ca;
}

h1.ant-typography.clinical-protocol-title,
.ant-typography h1.clinical-protocol-title {
    margin-bottom: 24px;
}

.clinical-protocol-checkbox-item.none-option {
    margin-top: 8px;
}

.clinical-protocol-radio-group span {
    color: #13171c;
}

.clinical-protocol .ant-checkbox + span {
    padding: 0 @md;
}
