.auth0-lock-content-wrapper {
    overflow-y: visible !important;
    height: calc(100vh - 80px - 120px) !important;
}

.auth0-lock-header {
    display: none !important;
}

.auth0-lock-widget {
    margin: 0 !important;
    width: auto !important;
}

.auth0-lock-cred-pane-internal-wrapper {
    height: 100% !important;
}

.auth0-lock.auth0-lock .auth0-lock-form {
    padding: 0px !important;
}

.auth0-lock.auth0-lock .auth0-lock-input-wrap.auth0-lock-input-wrap-with-icon {
    padding-left: 0 !important;
}

.auth0-lock.auth0-lock
    .auth0-lock-input-wrap.auth0-lock-input-wrap-with-icon
    svg {
    visibility: hidden;
}

#auth-signup .auth0-lock.auth0-lock .auth0-lock-input-wrap {
    padding-left: 0 !important;
    height: 56px;
    min-height: 56px;
    background: #ffffff !important;
    border: 1.5px solid #4e5a70;
    border-radius: 8px;
    margin-top: 12px;
    margin-bottom: 16px;
}

.auth0-lock-input {
    height: 56px !important;
    border-radius: 8px !important;
    font-size: 16px !important;
    font-family: Lato !important;
}

.auth0-label-submit,
.auth0-lock-submit {
    display: flex !important;
    flex-wrap: wrap !important;
    align-content: center !important;
    justify-content: center !important;
}

.auth0-label-submit {
    margin: auto;
}

#auth-signup,
.auth0-lock-widget-container,
.auth0-lock-cred-pane-internal-wrapper,
.auth0-lock-center {
    height: 100% !important;
}

.auth0-lock-input-block.auth0-lock-input-password::before {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    content: 'Password';
    color: #13171c;
}
.auth0-lock-input-block.auth0-lock-input-email {
    margin-top: 32px;
}

.auth0-lock-input-block.auth0-lock-input-email::before {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    content: 'Email address';
    color: #13171c;
}

.auth0-lock-input-block.auth0-lock-input-phone_number::before {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    content: 'Phone number';
    color: #13171c;
}

.auth0-lock-input-block.auth0-lock-input-given_name::before {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    content: 'Your first name';
    color: #13171c;
}

.auth0-lock-input-block.auth0-lock-input-family_name::before {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    content: 'Your last name';
    color: #13171c;
}
#auth-signup .auth0-lock-form > div::before {
    content: 'We use this information to match consultations to your profile.';
    color: #535762 !important;
    display: block;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
}

#auth-signup .auth0-lock-form > div {
    margin-top: 16px;
}

#auth-signup .auth0-lock-form::before {
    content: 'Create an account';
    display: block;
    font-family: Lato;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.550000011920929px;
    text-align: left;
    color: #13171c;
}

.auth0-lock.auth0-lock button.auth0-lock-submit {
    margin-bottom: 64px;
    width: auto;
    border-radius: 8px;
    height: 48px !important;
}

.auth0-lock.auth0-lock button.auth0-lock-submit .auth0-label-submit {
    text-transform: none;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
}

.auth0-lock.auth0-lock button.auth0-lock-submit svg {
    display: none;
}

.auth0-lock-error-msg {
    margin: 0px;
    font-size: 16px !important;
    font-family: Lato;
    color: #c3362e !important;
}
#auth-signup .auth0-lock-input-show-password .auth0-lock-focused {
    height: auto !important;
}
.auth0-lock-input-show-password
    .auth0-lock-show-password
    input[type='checkbox']
    + label {
    margin-top: 46px;
}

li {
    color: black !important;
}

.auth0-lock-password-strength.animated.fadeIn {
    border-radius: 8px;
    padding-top: 8px;
}
