@import '../../styles/spacing.less';
@import '../../styles/app.less';

.interstitial-icon > svg,
svg.interstitial-icon {
    margin-top: @xl;
    margin-bottom: @lg;
    height: 64px;
    color: @heading-color;
    stroke-width: 4px;
}

.center-text {
    text-align: center;
}

.text-dark {
    color: rgb(20, 23, 28) !important;
}

.interstitial-title {
    font-size: 32px !important;
    margin-bottom: @md;
    font-weight: 700 !important;
    line-height: 36px !important;
}

.interstitial-paragraph {
    font-size: 16px !important;
    line-height: 24px !important;
    font-weight: 400 !important;
}

@media screen and (min-width: @tablet) {
    .interstitial-icon > svg,
    svg.interstitial-icon {
        margin-top: @2xl;
    }
}

@media screen and (min-width: @desktop) {
    .interstitial-icon > svg,
    svg.interstitial-icon {
        margin-top: @5xl;
    }
}
