.chevron-icon {
    float: right;
}

.subheader .icon {
    width: 24px;
    height: 24px;
    display:  inline-block;
    vertical-align: text-bottom;
}

.alert-error {
        width: fit-content;
        left: 50%!important;
        top: 10%!important;
        margin: 0!important;
        transform: translate(-50%, -50%);
}