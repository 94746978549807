@import '../../styles/app.less';
@import '../../styles/spacing.less';
@import '../../styles/colours.less';

#checkout-title {
    margin-bottom: @lg + @2xs;
}

#checkout-cost {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: bold;
}

#checkout-cost * {
    margin-bottom: @xl;
}

#checkout-pay-cta {
    margin-top: @xs;
}

.checkout-infobox {
    padding: @lg @md;
}

.checkout-infobox--title {
    font-weight: bold;
    margin-bottom: 0 !important;
}

.checkout-infobox--desc {
    margin-bottom: 0 !important;

}

.checkout-infobox--desc button {
    padding: 0;
    border: 0;
    height: fit-content;
    width: fit-content;
}

#checkout .ant-card {
    margin-bottom: 0;
    box-shadow: none;
}

#checkout .ant-card-head {
    background-color: @grey-background;
    border-radius: @md @md 0 0;
    height: 56px;
    padding: @md;
    display: flex;
}

#checkout .ant-card-body {
    padding: @md @md @xl @md;
}

#checkout .ant-card-head-title {
    padding: 0;
    font-size: 18px;
    font-weight: bold;
}

.checkout-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(5px);
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkout-overlay--container {
    width: 232px;
    height: 236px;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.checkout-overlay--container svg {
    font-size: 50px;
}

.checkout-overlay--title {
    font-size: 30px;
    margin-top: @md;
}

.checkout-overlay--bold-desc {
    font-weight: bold;
}
