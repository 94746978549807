@import url('../../styles/spacing.less');

.wrapper {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: visible;
    height: calc(100% - 80px - 8px);
}

.inactivity-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-items: center;
    align-items: center;
}

.title {
    font-size: 36px;
}

.subTitle {
    text-align: center;
}

.button {
    width: 200px;
    margin-top: @lg;
}
