@import '../../styles/spacing.less';
@import '../../styles/colours.less';

.urac-card > .ant-col.ant-form-item-label {
    /* Heading */

    /* Auto Layout */

    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px 0px;

    position: static;
    width: 327px;

    left: 0px;
    top: 0px;

    /* CAP-gray/300 */

    background: #e9ecf0;
    border-radius: 16px 16px 0px 0px;

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px;

    width: 100%;
}

.text-container {
    display: flex;
    flex-direction: column;
}

.urac-card[data-collapsed='true'] > .ant-col.ant-form-item-label {
    border-radius: 16px;
}

.urac-card > .ant-form-item-control {
    padding: @lg @lg 0px @lg !important;
}

.urac-card .ant-form-item-label label {
    font-size: 18px !important;
}

.urac-multi-input .ant-form-item-label {
    padding-bottom: 16px !important;
}

.urac-multi-input {
    margin-top: @lg !important;
}

.ant-form-item {
    margin-bottom: 0 !important;
}

.urac-sub-header {
    font-size: 16px;
    line-height: 150%;

    color: #535762;

    font-weight: 400;
    margin-bottom: 12px;
}

.urac-tag {
    /* .God Chip */

    /* Auto Layout */

    border: none !important;

    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 8px !important;

    position: static !important;
    left: calc(50% - 75px / 2) !important;
    top: 0% !important;
    bottom: 0% !important;

    /* CAP-functional/light-info */

    background: #e3f4ff !important;
    border-radius: 100px !important;

    /* Inside Auto Layout */

    flex: none !important;
    order: 0 !important;
    flex-grow: 0 !important;
    flex-shrink: 1 !important;
    margin: 0px 0px !important;

    font-family: Lato !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 11px !important;
    line-height: 16px !important;
    /* identical to box height, or 145% */

    display: flex !important;
    align-items: center !important;
    text-align: center !important;
    text-transform: uppercase !important;
    font-feature-settings: 'liga' off !important;
}

span.urac-tag .ant-typography {
    color: #006fc3 !important;
}

.urac-save-button {
    margin-bottom: @lg !important;
    width: 100%;
}

.urac-card.ant-form-item {
    background: #fff;
    position: relative;
    z-index: 1;
    border-radius: 16px;
    margin-bottom: @md !important;
    flex-direction: row;
}

.urac-card > .ant-form-item-control {
    overflow: hidden;
    min-height: 0;
    max-height: 1000px;
    border: 1.5px solid #e9ecf0 !important;
    border-radius: 0 0 16px 16px !important;

    -moz-transition: max-height 0.5s;
    -ms-transition: max-height 0.5s;
    -o-transition: max-height 0.5s;
    -webkit-transition: max-height 0.5s;
    transition: max-height 0.5s;
}

.urac-card[data-collapsed='true'] > .ant-form-item-control {
    overflow: hidden;
    max-height: 0;
    border: 0 solid #e9ecf0 !important;
    padding: 0 !important;
}

.urac-card::after {
    content: ' ';
    box-shadow: 0px 4px 10px rgba(24, 39, 75, 0.12);
    border-radius: 16px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s;
}
.urac-card[data-active='true']::after {
    opacity: 1;
}

.urac-card .container {
    max-height: var(--max-height, 1000px);

    overflow: hidden;

    -moz-transition: max-height 0.5s;
    -ms-transition: max-height 0.5s;
    -o-transition: max-height 0.5s;
    -webkit-transition: max-height 0.5s;
    transition: max-height 0.5s;
}

.urac-checked {
    color: #0e7016 !important;
}

.urac-card .container[aria-hidden='true'] {
    max-height: 0;
}

.urac-card .container[aria-hidden='false'] {
    transition-delay: 0.5s;
}

.heading-container > .container[aria-hidden='false'] {
    margin-left: 12px;
}

.urac-card .save-container[aria-hidden='false'] {
    transition-delay: 0s;
}

.collapsed-card {
    margin-bottom: @md !important;
}

.collapsed-card {
    background-color: #e9ecf0;
    border-radius: 18px;
    font-weight: bold;
    font-size: 18px;
    color: #13171c !important;
    padding: 16px;
}

.urac-tag-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 24px;
}

.urac-tag-container .urac-tag {
    margin-bottom: 12px !important;
    margin-right: 12px !important;
}

.heading-container {
    align-items: center;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: auto 1fr auto;
}

.heading-container > *:first-child {
    padding-right: 8px;
}

.urac-card .ant-typography {
    color: black !important;
}

.urac-card .urac-edit-button > span {
    font-weight: bold;
    font-size: 16px;

    text-decoration-line: underline !important;

    color: #034d83 !important;
}

.urac-card .urac-edit-button {
    padding: 0;
    padding-right: 12px;
}

.ant-btn.ant-btn-primary.urac-save-button[disabled] {
    background: #4e5d70;
    opacity: 0.3;
    color: #ffffff;
}

.vec-radio-group-horizontal .vec-radio-button-horizontal {
    border: 2px solid #e9ecf0 !important;
}

.saved-answers > .ant-typography {
    padding-bottom: @lg;
}

.urac-card {
    flex-direction: row!important;
}