.informed-consent-container {
position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: calc(100% - 80px);
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}

.informed-consent-content {
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
  overflow-y: auto;
}

.informed-consent-button {
  height: 48px !important;
  width: 48px !important;
  margin-bottom: 32px;
  margin-top: 24px;

}

.informed-consent-button-container {
  display: flex;
  justify-content: center;
  background: white;
}

.informed-consent-button-container .primary-form-cta {
  margin: 32px 22px 64px !important;
  height: 56px;
}

.informed-consent-button-row {
  position: fixed;
  bottom: 0;
  left: 10px;
  right: 10px;
}

.button-padding {
  padding-bottom: 152px;
  padding-top: 8px;
}