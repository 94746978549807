@import '../../styles/app.less';

.footer {
    background-color: #034d83;
    padding: @3xl;
}

.footer .ant-typography {
    color: white;
    font-size: 16px !important;
}

.footer .link-list button, .footer .link-list a {
    width: fit-content;
    padding: 0;
    border: 0;
    height: fit-content;
    font-weight: unset;
}

.footer .link-list a div {
    height: fit-content;
    line-height: 1

}

.footer .ant-typography.copyright {
    font-size: 14px !important;
}

.footer .link-list {
    margin-top: @lg;
    margin-bottom: @lg;
    display: flex;
    flex-direction: column;
    gap: @md
}

.footer .link-list .ant-typography,
.footer .desc_1 {
    margin-bottom: 0;
}

.footer .desc_1 a {
    color: white;
    font-weight: bold;
    text-decoration: underline;
}

@media screen and (min-width: @tablet) {
    .footer .link-list {
        flex-direction: row;
        gap: @xl;
    }
}

.privacy-policy-modal .ant-modal-header{
    border: 0;
}

.privacy-policy-modal h1, .privacy-policy-modal h2  {
    text-align: center;
    font-size: 22px;
}

.privacy-policy-modal .ant-modal-content {
    height: 80vh;
    display: flex;
    flex-direction: column;
}

.privacy-policy-modal .ant-modal-body {
    overflow-y: auto;
}

.privacy-policy-modal .ant-modal-title {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
    color: #13171C;

}

.footer-container {
    max-width: 1024px;
    margin: 0 auto;
}

@media screen and (min-width: 1860px) {
    .footer-container {
        margin: 0 386px;
    }
}

