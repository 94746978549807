@import '../../styles/spacing.less';
@import '../../styles/app.less';
@import '../../styles/colours.less';
form {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.ant-btn-primary {
    border: none !important;
}

@media screen and (max-width: @mobile) {
    .ant-form-vertical .ant-form-item {
        flex-direction: row !important;
    }
}
.ant-form-item-control .ant-form-item-explain.ant-form-item-explain-error {
    align-self: flex-start;
    display: inline-block;
}

.ant-form-item-control
    .ant-form-item-explain.ant-form-item-explain-error:not(.sub-item-label
        .ant-form-item-explain.ant-form-item-explain-error)
    div:before {
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url('./alert-triangle-error-text-field.svg');
    vertical-align: middle;
    margin-right: 4px;
}

.ant-input,
.ant-picker,
.ant-input-number,
.ant-select.ant-select-single.ant-select-show-arrow .ant-select-selector {
    height: 56px;
    width: 100% !important;
    border-width: 1.5px !important;
}

div.ant-input-number {
    display: flex;
}
div.ant-input-number > div {
    flex-grow: 1;
}

.ant-form-item-label {
    line-height: @lg;
    font-weight: 700;
}
.ant-form-item-explain-error {
    align-self: flex-end;
}

.ant-select-selection-search,
.ant-input-number {
    display: table-cell;
    vertical-align: middle;
    margin: auto;
}

.ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-left: @2xs;
    color: #ff4d4f;
    font-size: @md;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}

div.ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    margin-right: 0px;
    content: '';
}

.anticon.anticon-arrow-left > svg {
    height: 0.75em;
    width: 0.75em;
    margin: auto;
    color: @primary-color;
}

.anticon.anticon-arrow-left {
    margin: auto;
}
.ant-page-header.ant-page-header-ghost {
    height: @4xl;
    border-bottom: 1px solid rgba(158, 172, 188, 30%);
    margin-bottom: @xl;
}
.ant-form-item-has-success .ant-input,
.ant-form-item-has-success .ant-input-number,
.ant-form-item-has-success
    .ant-select.ant-select-single.ant-select-show-arrow
    .ant-select-selector {
    background-color: #f9f9fb;
    border-color: #9ea9bc;
}

.ant-typography.header-button > strong {
    color: @primary-color;
}

.ant-page-header-heading {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.ant-form-item-with-help {
    margin-bottom: @lg;
}

.ant-select-selection-placeholder,
.ant-select-selection-item,
.ant-input-number {
    display: -webkit-flex;
    display: flex;
    align-items: center;
}

.sub-item-label .ant-form-item-label {
    font-weight: 400;
    color: @heading-color;
}

.sub-item-label.ant-form-item {
    margin-bottom: 0 !important;
}

.sub-item-label .ant-form-item-explain {
    min-height: 0 !important;
    height: 0 !important;
}

.item-group.ant-form-item {
    max-height: 139px;
    margin-bottom: 0px;
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-number,
.ant-form-item-has-error
    .ant-select.ant-select-single.ant-select-show-arrow
    .ant-select-selector {
    background-color: #fff1f1 !important;
    border-color: #c3362e !important;
}

.ant-form-item-has-error span.ant-form-item-children-icon {
    visibility: hidden !important;
}

.hidden-label > .ant-form-item-label {
    display: none;
}

.ant-input-suffix button.ant-btn.input-button {
    padding: 0px;
    color: #535762;
}

.health-profile
    .ant-row.ant-form-item:not(.sub-item-label, .item-group, .no-top-margin) {
    margin-top: 24px;
}

.stop-circle {
    position: relative;
    height: 46.67px;
    aspect-ratio: 1;
    margin-bottom: 32px;
}

.stop-circle > * {
    border: 4.5px solid #034d83;
    aspect-ratio: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.stop-circle > div:first-child {
    height: 46.67px;
    border-radius: 100%;
}
.stop-circle > div:last-child {
    height: 17px;
    border-radius: 3px;
}

.form-error-text {
    color: #c3362e!important;

}
.hidden-input {
    display: none!important;
}
